import React from "react";
import { OldStyledAccountSwitcherContainer } from "./AccountSwitcher.style";
import { AccountSwitcherProps } from "./AccountSwitcher.type";
import Account from "./Account";
import useOnAccountChange from "../../../../hooks/useOnAccountChange";

const AccountSwitcher: React.FC<AccountSwitcherProps> = ({ accounts }) => {
  const { onChange, selectedAccount } = useOnAccountChange();
  return (
    <OldStyledAccountSwitcherContainer data-testid="account-switcher-container">
      {accounts.map((account, index) => (
        <Account
          account={account}
          logoIndex={index}
          selected={account.accountId === selectedAccount.accountId}
          key={`tenant-account-${account.accountId}`}
          onClick={(account) => void onChange(account)}
        />
      ))}
    </OldStyledAccountSwitcherContainer>
  );
};

export default AccountSwitcher;
