import React from "react";

export default function fabricLogo() {
  return (
    <svg
      width="95"
      height="24"
      viewBox="0 0 95 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="21.3354" cy="12" rx="1.6001" ry="1.6001" fill="white" />
      <circle cx="21.3354" cy="21.333" r="1.6001" fill="white" />
      <ellipse cx="12.0015" cy="21.333" rx="1.6001" ry="1.6001" fill="white" />
      <ellipse
        cx="12.0018"
        cy="12.0028"
        rx="2.66683"
        ry="2.66683"
        fill="white"
      />
      <ellipse
        cx="2.66683"
        cy="2.66683"
        rx="2.66683"
        ry="2.66683"
        fill="white"
      />
      <ellipse
        cx="2.66683"
        cy="12.0029"
        rx="2.66683"
        ry="2.66683"
        fill="white"
      />
      <ellipse
        cx="12.0018"
        cy="2.66683"
        rx="2.66683"
        ry="2.66683"
        fill="white"
      />
      <ellipse
        cx="21.3348"
        cy="2.66683"
        rx="2.66684"
        ry="2.66683"
        fill="white"
      />
      <ellipse
        cx="2.66683"
        cy="21.3332"
        rx="2.66683"
        ry="2.66683"
        fill="white"
      />
      <path
        d="M49.2098 8.20431V10.4498C48.1713 8.85707 46.5097 7.86487 44.3029 7.86487C40.6941 7.86487 37.7344 10.8676 37.7344 14.732C37.7344 18.5963 40.6941 21.599 44.3029 21.599C46.5097 21.599 48.1713 20.6068 49.2098 19.0141V21.2596H51.4685V8.20431H49.2098ZM44.5885 19.4058C41.9922 19.4058 39.9931 17.3952 39.9931 14.732C39.9931 12.0687 41.9922 10.0582 44.5885 10.0582C47.2107 10.0582 49.2098 12.0687 49.2098 14.732C49.2098 17.3952 47.2107 19.4058 44.5885 19.4058Z"
        fill="white"
      />
      <path
        d="M61.0348 7.96001C58.828 7.96001 57.1664 8.95493 56.1279 10.552V3.06396H53.8691V21.3914H56.1279V19.1398C57.1664 20.7369 58.828 21.7318 61.0348 21.7318C64.6436 21.7318 67.6033 18.7208 67.6033 14.8459C67.6033 10.9709 64.6436 7.96001 61.0348 7.96001ZM60.7492 19.5325C58.127 19.5325 56.1279 17.5165 56.1279 14.8459C56.1279 12.1753 58.127 10.1593 60.7492 10.1593C63.3455 10.1593 65.3446 12.1753 65.3446 14.8459C65.3446 17.5165 63.3455 19.5325 60.7492 19.5325Z"
        fill="white"
      />
      <path
        d="M71.6294 10.4368V8.23626H69.3379V21.3346H71.6294V14.3925C71.6294 11.3013 73.7628 10.3058 75.7383 10.3844V8.00049C74.0789 8.00049 72.4722 8.60301 71.6294 10.4368Z"
        fill="white"
      />
      <path d="M79.8708 21.3341H77.7373V8.2666H79.8708V21.3341Z" fill="white" />
      <path
        d="M88.697 21.7323C91.2409 21.7323 93.4473 20.3613 94.5376 18.3049L92.6167 17.1713C91.8899 18.5422 90.4622 19.4649 88.697 19.4649C86.1013 19.4649 84.1285 17.4349 84.1285 14.7985C84.1285 12.1357 86.1013 10.1057 88.697 10.1057C90.4362 10.1057 91.8899 11.0284 92.5388 12.4257L94.4337 11.3184C93.4473 9.23568 91.2669 7.86475 88.697 7.86475C84.7774 7.86475 81.8701 10.8966 81.8701 14.7985C81.8701 18.7004 84.7774 21.7323 88.697 21.7323Z"
        fill="white"
      />
      <path
        d="M37.2021 4.88482V2.68826C33.4396 2.42676 31.4399 4.38798 31.4399 7.86586V8.2581H29.335V10.4547H31.4399V21.3328H33.729V10.4547H37.2021V8.2581H33.729V7.86586C33.729 5.59085 34.913 4.70177 37.2021 4.88482Z"
        fill="white"
      />
      <ellipse
        cx="78.8041"
        cy="4.39738"
        rx="1.33342"
        ry="1.33342"
        fill="white"
      />
    </svg>
  );
}
