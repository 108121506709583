import React, { useEffect, useRef, useState } from "react";
import {
  StyledAccountList,
  StyledAccountSwitcherContainer,
  StyledAccountSearchContainer,
  StyledAccountSearchInput,
} from "./AccountSwitcher.style";
import { AccountSwitcherProps } from "./AccountSwitcher.type";
import useOnAccountChange from "../../../hooks/useOnAccountChange";
import { Icon } from "ds4-beta";
import Account from "./Account";

const AccountSwitcher: React.FC<AccountSwitcherProps> = ({
  accounts,
  colorMap,
}) => {
  const { onChange, selectedAccount } = useOnAccountChange();

  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value: string) => {
    setFilteredAccounts(
      accounts.filter(
        (account) =>
          account.name.toLowerCase().includes(value.toLowerCase()) ||
          account.accountId.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  return (
    <StyledAccountSwitcherContainer data-testid="account-switcher-container">
      <StyledAccountSearchContainer>
        <StyledAccountSearchInput
          placeholder="Search account name or ID"
          value={searchValue}
          data-testid="account-switcher-search-input"
          onChange={(e) => {
            setSearchValue(e.target.value);
            handleSearch(e.target.value);
          }}
        />
        {searchValue && (
          <button
            onClick={() => {
              setSearchValue("");
            }}
            style={{
              all: "unset",
              cursor: "pointer",
            }}
            data-testid="account-switcher-clear-button"
          >
            <Icon iconName="Close" size={16} />
          </button>
        )}
        <Icon iconName="Search" size={16} />
      </StyledAccountSearchContainer>
      <StyledAccountList>
        {filteredAccounts.map((account, index) => (
          <Account
            key={`tenant-account-${account.accountId}`}
            account={account}
            selected={account.accountId === selectedAccount.accountId}
            color={colorMap.get(account.accountId)}
            onClick={onChange}
          />
        ))}
      </StyledAccountList>
    </StyledAccountSwitcherContainer>
  );
};

export default AccountSwitcher;
