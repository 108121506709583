import { useEffect, useRef } from "react";
import { AccountProps } from "./AccountSwitcher.type";
import {
  StyledAccountName,
  StyledAccountDetails,
  StyledAccountId,
  StyledAccountInfoWrapper,
} from "./AccountSwitcher.style";
import AccountLogo from "./AccountLogo";

export default function Account({
  onClick,
  account,
  selected,
  color,
}: AccountProps) {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, []);
  return (
    <StyledAccountDetails
      key={`tenant-account-${account.accountId}`}
      onClick={() => {
        !selected && onClick(account);
      }}
      selected={selected}
      data-testid={`account-switch-${account.accountId}`}
      ref={ref}
    >
      <StyledAccountInfoWrapper>
        <StyledAccountName>{account.name}</StyledAccountName>
        <StyledAccountId>ID: {account.accountId}</StyledAccountId>
      </StyledAccountInfoWrapper>
      <AccountLogo color={color}>{account.name.slice(0, 2)}</AccountLogo>
    </StyledAccountDetails>
  );
}
