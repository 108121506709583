import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "ds4-beta";
import App from "./App";
import {
  FlagProvider,
  IConfig as IUnleashConfig,
  useFlagsStatus,
} from "@unleash/proxy-client-react";
import { SESSION_STORAGE_KEYS, UNLEASH_APP_NAME } from "./constants";
import { BrowserRouter } from "react-router-dom";
import DS4PageLoader from "./App/LeftNavigationLoader";

const unleashStorageProvider = (appName: string) => {
  const prefix: Readonly<string> = "unleash";
  return {
    save: async (name: string, data: any) => {
      const repo = JSON.stringify(data);
      const key = `${prefix}:${appName}:${name}`;
      try {
        window.localStorage.setItem(key, repo);
      } catch (e) {
        console.error(`LeftNavStorageProvider Save Error: ${e}`);
      }
    },
    get: async (name: string) => {
      try {
        const key = `${prefix}:${appName}:${name}`;
        const data = window.localStorage.getItem(key);
        return data ? JSON.parse(data) : undefined;
      } catch (e) {
        console.error(`LeftNavStorageProvider Get Error: ${e}`);
      }
    },
  };
};

export const unleashConfig = {
  url: process.env.UNLEASH_PROXY_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  disableRefresh: true,
  appName: UNLEASH_APP_NAME,
  storageProvider: unleashStorageProvider(UNLEASH_APP_NAME),
  context: {
    userId: window.sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT_ID),
  },
} as IUnleashConfig;

const DS4Switch = ({ children }: { children: React.ReactNode }) => {
  const { flagsReady } = useFlagsStatus();
  if (!flagsReady) {
    return <DS4PageLoader />;
  }

  return <>{children}</>;
};

export default function Root() {
  return (
    <FlagProvider config={unleashConfig}>
      <DS4Switch>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </DS4Switch>
    </FlagProvider>
  );
}
