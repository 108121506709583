import styled, { css } from "styled-components";
import { theme } from "ds4-beta";

export const OldStyledAccountSwitcherContainer = styled.div<{}>`
  box-sizing: border-box;
  height: 100vh;
  background: ${theme.color.grey[900]};
  padding: ${theme.space[3]};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const OldStyledAccountSwitchButton = styled.div<{ selected: boolean }>`
  all: unset;
  &:not(:first-child) {
    margin-top: ${theme.space[4]};
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.space[1]};
`;

const OldStyledLogo = css`
  border-radius: ${theme.space[1]};
  height: 40px;
  width: 40px;
`;

export const OldStyledTenantLogo = styled.div<{ selected: boolean }>`
  ${OldStyledLogo};
  box-sizing: border-box;
  ${({ selected }) =>
    selected
      ? `box-shadow: 0px 0px 0px 2px ${theme.color.grey[0]}`
      : `
                  &:hover {
                      box-shadow: 0px 0px 0px 2px ${theme.color.grey[300]};
                  }
              `}
`;

export const OldStyledTenantImageLogo = styled.img`
  ${OldStyledLogo};
`;

export const OldStyledAccountName = styled.div`
  width: 64px;
  margin-top: ${theme.space[2]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  ${theme.typography.smallText};
  color: ${theme.color.grey[0]};
`;

export const OldStyledBadge = styled.div<{}>`
  ${theme.typography.body2.medium}
  box-sizing: border-box;
  font-size: ${theme.space[2]};
  min-width: 14px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.25px;
  position: absolute;
  color: ${theme.color.grey[1000]};
  border-radius: 50%;
  padding: 2px;
  background: ${theme.color.grey[0]};
  top: 0px;
  right: 0px;
`;
