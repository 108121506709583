import React, { useEffect, useRef } from "react";
import { Tooltip } from "ds4-beta";
import AccountLogo from "./AccountLogo";
import {
  OldStyledAccountName,
  OldStyledAccountSwitchButton,
  OldStyledTenantImageLogo,
  OldStyledTenantLogo,
} from "./AccountSwitcher.style";
import { AccountProps } from "./AccountSwitcher.type";

export default function Account({
  onClick,
  account,
  selected,
  logoIndex,
}: AccountProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selected) {
      ref.current.scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }
  }, []);
  return (
    <OldStyledAccountSwitchButton
      ref={ref}
      selected={selected}
      onClick={() => !selected && onClick(account)}
      data-testid={`account-switch-${account.accountId}`}
      role="button"
      aria-label={`switch to ${account.name} account`}
      tabIndex={1}
    >
      <Tooltip
        id={`account-switch-tooltip-${account.accountId}`}
        text={account.name}
        showArrow={false}
        placement={"right"}
        theme="light"
      >
        <OldStyledTenantLogo selected={selected}>
          {account.logoURL ? (
            <OldStyledTenantImageLogo
              width={40}
              height={40}
              src={account.logoURL}
            />
          ) : (
            <AccountLogo index={logoIndex} />
          )}
        </OldStyledTenantLogo>
      </Tooltip>
      <OldStyledAccountName>{account.name}</OldStyledAccountName>
    </OldStyledAccountSwitchButton>
  );
}
