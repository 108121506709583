import React, { forwardRef } from "react";
import { StyledAccountLogo } from "./AccountSwitcher.style";
import { motion } from "framer-motion";

type AccountLogoProps = Readonly<{
  color: string;
  children: React.ReactNode;
  onClick?: () => void;
  clickable?: boolean;
  active?: boolean;
  withMotion?: boolean;
}>;

const MotionStyledAccountLogo = motion(StyledAccountLogo);

function AccountLogo(
  {
    color,
    children,
    onClick,
    clickable = false,
    active = false,
    withMotion = false,
  }: AccountLogoProps,
  ref
) {
  return (
    <MotionStyledAccountLogo
      data-testid="account-logo"
      style={{ backgroundColor: color }}
      onClick={onClick}
      ref={ref}
      layoutId={withMotion ? "account-logo" : undefined}
      data-clickable={clickable ? "true" : "false"}
      data-active={clickable && active ? "true" : "false"}
    >
      {children}
    </MotionStyledAccountLogo>
  );
}

export default forwardRef(AccountLogo);
