import styled, { css } from "styled-components";
import { CollapsedType } from "..";
import { theme } from "ds4-beta";

export const StyledHeader = styled.div<{
  multiAccount?: boolean;
  collapsed?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${theme.space[3]};
  justify-content: center;
  align-items: flex-start;
  padding: ${({ multiAccount, collapsed }) =>
      collapsed && !multiAccount ? "0px" : theme.space[2]}
    0px;
  ${({ collapsed }) =>
    !collapsed
      ? css`
          padding-left: ${theme.space[3]};
          padding-right: ${theme.space[3]};
        `
      : css`
          align-items: center;
        `}
`;

export const StyledLogoContainer = styled.div<{
  multiAccount: boolean;
  collapsed: boolean;
}>`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  box-sizing: border-box;
  padding-left: ${({ multiAccount }) =>
    multiAccount ? theme.space[1] : "10px"};
`;

export const StyledAccountSwitcher = styled.button<CollapsedType>`
  all: unset;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAccountSwitcherButton = styled.button`
  all: unset;
  width: 100%;
  border: 1px solid ${theme.color.grey[600]};
  border-radius: 4px;
  padding: ${theme.space[1]} ${theme.space[2]};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${theme.space[4]};
  justify-content: space-between;
  background-color: ${theme.color.grey[1000]};
  :hover {
    border: 1px solid ${theme.color.grey[400]};
    background-color: ${theme.color.navigation.hover};
    [data-testid="copy-icon"] {
      display: block;
    }
  }
`;

export const StyledCopyIcon = styled.button`
  all: unset;
  cursor: pointer;
  display: none;
  :hover {
  }
`;

export const StyledAccountLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #2bd2c8;
  ${theme.typography.body1.medium}
  color: ${theme.color.grey[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const StyledAccountName = styled.div`
  ${theme.typography.fieldLabel}
  color: ${theme.color.grey[0]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledAccountId = styled.div`
  ${theme.typography.smallText}
  color: ${theme.color.grey[300]};
  display: flex;
  align-items: center;
  gap: ${theme.space[1]};
  span {
    color: ${theme.color.grey[300]};
    ${theme.typography.smallText}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledAccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space[1]};
  flex: 1;
  min-width: 0px;
`;
