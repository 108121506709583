import React from "react";
import {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
} from "../../../../assets";
export default function AccountLogo({ index }: { index: number }) {
  const Logos = [
    <One key="Logo-1" />,
    <Two key="Logo-2" />,
    <Three key="Logo-3" />,
    <Four key="Logo-4" />,
    <Five key="Logo-5" />,
    <Six key="Logo-6" />,
    <Seven key="Logo-7" />,
    <Eight key="Logo-8" />,
  ];
  return Logos[index % Logos.length];
}
