import ProductNavLinkType from "../types/ProductNavLink.type";
import { LEFT_NAV_RESTRUCTURE_FF } from "../constants";

const INTEGRATIONS_FF = "integrations-nav";

const IntegrationsLink: ProductNavLinkType = {
  icon: "Integrations",
  label: "Integrations",
  children: [],
  featureFlag: {
    flags: [LEFT_NAV_RESTRUCTURE_FF, INTEGRATIONS_FF],
    flagExpression: (leftNavRestructure, integrations) =>
      leftNavRestructure && integrations,
  },
  url: "/integrations",
};

export default IntegrationsLink;
