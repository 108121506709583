import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const ProductLinks = {
  icon: "Product",
  label: "Product Catalog",
  featureFlag: {
    flags: ["copilot-header-pim", "ui-product-rebranding-to-product-catalog"],
    flagExpression: (a, b) => {
      return a && !b;
    },
  },
  children: [
    {
      label: "List",
      children: [
        {
          label: "Items",
          url: COPILOT_ROUTES.pim.items,
          children: [],
        },
        {
          label: "Bundles",
          url: COPILOT_ROUTES.pim.bundles,
          children: [],
        },
      ],
      url: "/",
    },
    {
      label: "Attributes",
      children: [
        {
          label: "Product",
          url: COPILOT_ROUTES.pim.productAttributes,
          children: [],
        },
        {
          label: "Category",
          url: COPILOT_ROUTES.pim.categoryAttributes,
          children: [],
        },
      ],
    },
    {
      label: "Categories",
      url: COPILOT_ROUTES.pim.categories,
      children: [],
    },
    {
      label: "Collections",
      url: COPILOT_ROUTES.pim.collections,
      children: [],
    },
    {
      label: "Background Jobs",
      url: COPILOT_ROUTES.pim.jobs,
      featureFlag: "pim-v3-tenant",
      children: [],
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.pim.settings.basePath,
      children: [
        {
          label: "Attribute groups",
          url: COPILOT_ROUTES.pim.settings.attributeGroup,
          children: [],
        },
        {
          label: "Attribute mapping",
          url: COPILOT_ROUTES.pim.settings.attributeMap,
          children: [],
        },
      ],
    },
  ],
} satisfies ProductNavLinkType;

export default ProductLinks;
