import React, { useState } from "react";
import _isFunction from "lodash/isFunction";
import { BasePopover, theme, Tooltip } from "ds4-beta";
import {
  StyledAccountId,
  StyledAccountInfoWrapper,
  StyledAccountLogo,
  StyledAccountName,
  StyledAccountSwitcherButton,
  StyledHeader,
  StyledLogoContainer,
} from "./LeftNavHeader.style";
import { LeftNavHeaderProps } from "./LeftNavHeader.type";
import fabricLogo from "./fabricLogo";
import AccountSwitcher from "../AccountSwitcher";
import useOnAccountChange from "../../../hooks/useOnAccountChange";
import AccountLogo from "../AccountSwitcher/AccountLogo";
import CopyIcon from "./CopyIcon";

const colors = [
  "#02E0D3",
  "#FD44F5",
  "#FFC738",
  "#189CFB",
  "#00E484",
  "#943EFF",
  "#FF4D8D",
  "#FF8B37",
];

const LeftNavHeader = ({
  collapsed,
  onClick,
  multiAccount,
  accounts,
}: LeftNavHeaderProps): JSX.Element => {
  const onClickLogo = (event: React.MouseEvent<HTMLElement>): void => {
    if (_isFunction(onClick)) {
      onClick(event);
    }
  };
  const [openAccountSwitcher, setOpenAccountSwitcher] = useState(false);
  const { selectedAccount } = useOnAccountChange();

  const colorMap = new Map<string, string>();
  accounts.forEach((account, index) => {
    colorMap.set(account.accountId, colors[index % colors.length]);
  });

  return (
    <StyledHeader
      data-testid="left-nav-header"
      collapsed={collapsed}
      multiAccount={multiAccount}
    >
      {!collapsed && (
        <StyledLogoContainer
          collapsed={!!collapsed}
          multiAccount={multiAccount}
          data-testid="fabric-logo"
          onClick={onClickLogo}
        >
          {fabricLogo()}
        </StyledLogoContainer>
      )}
      {collapsed ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BasePopover
            controlled={{
              open: openAccountSwitcher,
              onOpenChange: setOpenAccountSwitcher,
            }}
            style={{
              padding: 0,
              overflow: "hidden",
              borderRadius: "4px",
              border: `1px solid ${theme.color.grey[800]}`,
            }}
            trigger={
              <StyledAccountLogo
                style={{ visibility: "hidden", height: "0px" }}
              />
            }
            placement="right-start"
          >
            <AccountSwitcher accounts={accounts} colorMap={colorMap} />
          </BasePopover>
          <Tooltip
            text={[selectedAccount.name, `ID: ${selectedAccount.accountId}`]}
            placement="bottomStart"
            theme="grey"
          >
            <AccountLogo
              color={colorMap.get(selectedAccount.accountId)}
              onClick={() => setOpenAccountSwitcher(true)}
              clickable
              active={openAccountSwitcher}
              withMotion
            >
              {selectedAccount.name.slice(0, 2)}
            </AccountLogo>
          </Tooltip>
        </div>
      ) : (
        <BasePopover
          controlled={{
            open: openAccountSwitcher,
            onOpenChange: setOpenAccountSwitcher,
          }}
          style={{
            padding: 0,
            overflow: "hidden",
            borderRadius: "4px",
            border: `1px solid ${theme.color.grey[800]}`,
          }}
          trigger={
            <StyledAccountSwitcherButton
              data-testid="account-switcher-toggle"
              aria-label="switch between mutiple accounts"
              style={{
                borderColor: openAccountSwitcher
                  ? theme.color.grey[0]
                  : undefined,
                backgroundColor: openAccountSwitcher
                  ? theme.color.navigation.active
                  : undefined,
              }}
              onClick={() => setOpenAccountSwitcher(!openAccountSwitcher)}
              tabIndex={1}
            >
              <StyledAccountInfoWrapper>
                <StyledAccountName>{selectedAccount.name}</StyledAccountName>
                <StyledAccountId>
                  <span>ID: {selectedAccount.accountId}</span>
                  <CopyIcon accountId={selectedAccount.accountId} />
                </StyledAccountId>
              </StyledAccountInfoWrapper>
              <AccountLogo
                color={colorMap.get(selectedAccount.accountId)}
                withMotion
              >
                {selectedAccount.name.slice(0, 2)}
              </AccountLogo>
            </StyledAccountSwitcherButton>
          }
          placement="right-start"
        >
          <AccountSwitcher accounts={accounts} colorMap={colorMap} />
        </BasePopover>
      )}
    </StyledHeader>
  );
};

export default LeftNavHeader;
