import styled, { css } from "styled-components";
import { theme } from "ds4-beta";

export const StyledAccountSwitcherContainer = styled.div<{}>`
  box-sizing: border-box;
  min-height: 0px;
  max-height: min(80vh, 464px);
  width: 336px;
  background: ${theme.color.grey[900]};
  padding: ${theme.space[3]} ${theme.space[2]};
  display: flex;
  flex-direction: column;
  gap: ${theme.space[2]};
  justify-content: flex-start;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  * {
    box-sizing: border-box;
  }
`;

export const StyledAccountList = styled.ul`
  flex: 1;
  min-height: 0px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  padding-right: ${theme.space[1]};
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.color.grey[0]};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.color.grey[400]};
    border-radius: 10px;
  }
`;

export const StyledAccountSwitchButton = styled.div<{ selected: boolean }>`
  all: unset;
  &:not(:first-child) {
    margin-top: ${theme.space[4]};
  }
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.space[1]};
`;

const StyledLogo = css`
  border-radius: ${theme.space[1]};
  height: 40px;
  width: 40px;
`;

export const StyledTenantLogo = styled.div<{ selected: boolean }>`
  ${StyledLogo};
  box-sizing: border-box;
  ${({ selected }) =>
    selected
      ? `box-shadow: 0px 0px 0px 2px ${theme.color.grey[0]}`
      : `
                  &:hover {
                      box-shadow: 0px 0px 0px 2px ${theme.color.grey[300]};
                  }
              `}
`;

export const StyledTenantImageLogo = styled.img`
  ${StyledLogo};
`;

export const StyledBadge = styled.div<{}>`
  ${theme.typography.body2.medium}
  box-sizing: border-box;
  font-size: ${theme.space[2]};
  min-width: 14px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.25px;
  position: absolute;
  color: ${theme.color.grey[1000]};
  border-radius: 50%;
  padding: 2px;
  background: ${theme.color.grey[0]};
  top: 0px;
  right: 0px;
`;

export const StyledAccountName = styled.p`
  all: unset;
  ${theme.typography.body1.regular}
  color: ${theme.color.grey[0]};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledAccountDetails = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.space[1]};
  padding: ${theme.space[1]} ${theme.space[2]};
  cursor: pointer;
  border-radius: ${theme.space[1]};
  border: 1px solid transparent;
  &:not(:first-child) {
    margin-top: ${theme.space[2]};
  }
  :hover {
    background-color: ${theme.color.grey[1000]};
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${theme.color.grey[1000]};
      border: 1px solid ${theme.color.grey[400]};
    `}
`;

export const StyledAccountId = styled.div`
  ${theme.typography.smallText}
  color: ${theme.color.grey[300]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledAccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space[1]};
  flex: 1;
  min-width: 0px;
`;

export const StyledAccountLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #2bd2c8;
  ${theme.typography.body1.medium}
  color: ${theme.color.grey[1000]};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  &[data-clickable="true"] {
    border: 1px solid transparent;
    cursor: pointer;
    :hover {
      border: 1px solid ${theme.color.grey[400]};
    }
    &[data-active="true"] {
      border: 1px solid ${theme.color.grey[0]};
    }
  }
`;

export const StyledAccountSearchContainer = styled.div`
  display: flex;
  background-color: ${theme.color.grey[100]};
  padding: ${theme.space[2]} ${theme.space[4]};
  padding-right: ${theme.space[3]};
  border-radius: 24px;
  width: 100%;
  gap: ${theme.space[2]};
  border: 1px solid transparent;
  :active,
  :focus,
  :focus-within {
    border: 1px solid ${theme.color.blue[500]};
    background-color: ${theme.color.grey[0]};
  }
`;

export const StyledAccountSearchInput = styled.input`
  all: unset;
  ${theme.typography.body2.regular};
  ::placeholder {
    color: ${theme.color.grey[600]};
  }
  color: ${theme.color.grey[1000]};
  flex: 1;
  min-width: 0px;
  :active,
  :focus {
    ::placeholder {
      color: ${theme.color.grey[400]};
    }
  }
`;
