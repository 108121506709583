import { Icon, theme, Tooltip } from "ds4-beta";
import { StyledCopyIcon } from "./LeftNavHeader.style";
import { useState } from "react";

const CopyIcon = ({ accountId }: { accountId: string }) => {
  const [copyText, setCopyText] = useState("Copy ID");

  const handleCopy = () => {
    navigator.clipboard.writeText(accountId);
    setCopyText("Copied!");
  };

  return (
    <Tooltip text={copyText} theme="grey">
      <StyledCopyIcon
        data-testid="copy-icon"
        onClick={(e) => {
          e.stopPropagation();
          handleCopy();
        }}
        onMouseLeave={() => {
          setCopyText("Copy ID");
        }}
      >
        <Icon iconName="Copy" size={12} color={theme.color.grey[300]} />
      </StyledCopyIcon>
    </Tooltip>
  );
};

export default CopyIcon;
