import styled from "styled-components";
import { theme } from "ds4-beta";
import { CollapsedType } from "../..";

export const StyledHeader = styled.div<{
  multiAccount?: boolean;
  collapsed?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ multiAccount, collapsed }) =>
      collapsed && !multiAccount ? "0px" : theme.space[2]}
    0px;
`;

export const StyledLogoContainer = styled.div<{
  multiAccount: boolean;
  collapsed: boolean;
}>`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  box-sizing: border-box;
  padding-left: ${({ multiAccount }) =>
    multiAccount ? theme.space[1] : "10px"};
`;

export const StyledAccountSwitcher = styled.button<CollapsedType>`
  all: unset;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
