import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const RenamedProductLinks = {
  icon: "Product",
  label: "Products",
  // featureFlag: "copilot-header-pim",
  featureFlag: {
    flags: ["copilot-header-pim", "ui-product-rebranding-to-product-catalog"],
    flagExpression: (a, b) => a && b,
  },
  children: [
    {
      label: "List",
      children: [
        {
          label: "Items",
          url: "/products/items",
          children: [],
        },
        {
          label: "Bundles",
          url: "/products/bundles",
          children: [],
        },
      ],
      url: "/",
    },
    {
      label: "Attributes",
      children: [
        {
          label: "Product",
          url: "/products/attributes/product",
          children: [],
        },
        {
          label: "Category",
          url: "/products/attributes/category",
          children: [],
        },
      ],
    },
    {
      label: "Categories",
      url: "/products/category",
      children: [],
    },
    {
      label: "Collections",
      url: "/products/collection",
      children: [],
    },
    {
      label: "Background Jobs",
      url: "/products/jobs",
      featureFlag: "pim-v3-tenant",
      children: [],
    },
    {
      label: "Settings",
      url: "/products/settings",
      children: [
        {
          label: "Attribute groups",
          url: "/products/settings/attribute-group",
          children: [],
        },
        {
          label: "Attribute mapping",
          url: "/products/settings/attribute-mapping",
          children: [],
        },
      ],
    },
  ],
} satisfies ProductNavLinkType;

export default RenamedProductLinks;
