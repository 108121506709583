import { useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import ProductNavLinkType from "../types/ProductNavLink.type";
import { useLocation } from "react-router-dom";
import { defaultNavLinkData } from "../data";
import useIsEnabled from "./useIsEnabled";
import { PRODUCT_NAME_MAPPING } from "../constants";
import useSelectedOrgProducts from "./useSelectedOrgProducts";

/**
 * This is the place which contains all the logic related to active entry, expanded entry, and unleash. (RBAC in future if needed)
 * @returns Process Links
 */
const useNavLinks = () => {
  const [navLinks, setNavLinks] = useState<ProductNavLinkType[]>([]);
  const productsLabelRename = useFlag("product-app-rename");
  const isEnabled = useIsEnabled();
  const { pathname } = useLocation();
  const selfOnboarding = isEnabled("copilot-self-onboarding");
  const selectedOrgProducts = useSelectedOrgProducts(selfOnboarding);
  const tenantDetail = window.sessionStorage?.tenantDetail;

  /**
   * This is the a part of the isActive function
   */
  const lastActiveNavItem = {
    length: -1,
    lastRef: null,
  } as {
    length: number;
    lastRef: ProductNavLinkType;
  };
  /**
   * This utility function determines the best match for the pathname and sets it as active
   */
  const isActive = (navItem: ProductNavLinkType) => {
    const startIndex = pathname.indexOf(navItem.url); // must return 0 if the pathname has url as prefix
    if (startIndex !== 0) return false;
    if (
      navItem.url.length > lastActiveNavItem.length &&
      lastActiveNavItem.lastRef
    ) {
      lastActiveNavItem.lastRef.activeTab = false;
    }
    lastActiveNavItem.length = navItem.url.length;
    lastActiveNavItem.lastRef = navItem;
    return startIndex == 0;
  };

  const isNotEmpty = (items: ProductNavLinkType[]) => items && items.length > 0;

  const update = (navItem: ProductNavLinkType) => {
    if (
      !isEnabled(
        navItem.featureFlag,
        navItem.rbacAllowedPermissions,
        navItem.enabled,
        navItem.flagState
      )
    )
      return null;
    const updatedChildren: ProductNavLinkType[] = isNotEmpty(navItem.children)
      ? navItem.children
          .map((item) => update(item))
          .filter((item) => item !== null)
      : [];
    const computedLink = {
      ...navItem,
      children: updatedChildren,
      expanded: updatedChildren.reduce(
        (childActive, child) =>
          childActive || child.activeTab || child.expanded,
        false
      ),
      url: isNotEmpty(updatedChildren) ? updatedChildren[0].url : navItem.url,
    } satisfies ProductNavLinkType;
    computedLink.activeTab =
      updatedChildren.length === 0 && isActive(computedLink);

    return computedLink;
  };

  const filterNonProvisionedProducts = (navItem: ProductNavLinkType) => {
    // do not filter out Settings or Home
    if (
      ["Settings", "Home", "Analytics", "Welcome", "Alerts"].includes(
        navItem.label
      )
    ) {
      return true;
    }

    // if no tenant detail is available when copilot-self-onboarding ff is on,
    // then do not render any fabric product links
    if (selfOnboarding && !tenantDetail) {
      return false;
    }
    if (selfOnboarding) {
      const { status } = JSON.parse(tenantDetail);
      // if self-onboarding is enabled AND if status is not ACTIVE,
      // do not render any other fabric products
      if (status !== "ACTIVE") {
        return false;
      }
      // if status is active, check which products are enabled
      // and only render fabric products that have been provisioned for the organization
      return (
        Array.isArray(selectedOrgProducts) &&
        selectedOrgProducts.some((product: string) =>
          PRODUCT_NAME_MAPPING[navItem.label].includes(product)
        )
      );
    }

    return true;
  };

  useEffect(() => {
    let _navLinks = defaultNavLinkData
      .filter((item) => filterNonProvisionedProducts(item))
      .map((link) => update(link))
      .filter((item) => item !== null);

    if (productsLabelRename) {
      _navLinks = _navLinks.map((link) =>
        link.label === "Products" ? { ...link, label: "Product Catalog" } : link
      );
    }

    setNavLinks(_navLinks);
  }, [
    pathname,
    selectedOrgProducts,
    tenantDetail,
    selfOnboarding,
    productsLabelRename,
  ]);
  return navLinks;
};

export default useNavLinks;
