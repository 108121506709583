export const SESSION_STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  ACCOUNT: "account",
  ACCOUNT_ID: "accountId",
  ORG_DETAIL: "orgDetail",
  TENANT_DETAIL: "tenantDetail",
  ACCOUNT_NAME: "accountName",
};

export const LOCAL_STORAGE_KEYS = {
  IS_ID_V2: "isLoggedInWithIdV2",
};

export const UNLEASH_APP_NAME = "mfa-left-navigation";

export const LEFT_NAV_RESTRUCTURE_FF = "left-nav-restructure";
export const FABAI_HOME_PAGE_FF = "fabai-home-page";

export const API_BASE_URL = "tenants.fabric.inc/v1";

export * from "./rbac_constants";

export const PRODUCT_NAME_MAPPING = {
  Products: ["PIM"],
  "Product Catalog": ["PIM"],
  Orders: ["OMS"],
  Inventory: ["OMS"],
  Offers: ["OFFERS"],
  Customers: ["CDP"],
  Experiences: ["XM"],
  Marketplace: ["DROPSHIP"],
  Loyalty: ["Loyalty"],
  "Catalog Connector": ["CATALOG_CONNECTOR"],
  Suppliers: ["SUPPLIERS"],
};

export const EXCLUDED_ORGANIZATION_PRODUCTS = [
  "Settings",
  "Home",
  "Analytics",
  "Welcome",
  "Alerts",
  "Integrations",
];

export const SUPPORT_PAGE_LINK =
  "https://support.fabric.inc/hc/en-us/requests/new";

export const FOOTER_LINKS = ["Settings", "Alerts", "Integrations"];

export const NEW_ACCOUNT_SWITCHER_FF = "new-account-switcher";
